const Module = () => import('./Module.vue')
const ExecutiveReport = () => import('./views/ExecutiveReport.vue')

const moduleRoute = {
  path: '/executive-report',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'ExecutiveReport',
      path: ':userId',
      component: ExecutiveReport,
      props: route => ({
        userId: route.params.userId,
        campaignId: route.query.campaignId,
        pdf: String(route.query.pdf).toLowerCase() === 'true',
        anonymized: !route.query.anonymized || String(route.query.anonymized).toLowerCase() === 'true',
        isTeam: String(route.query.isTeam) === 'true'
      })
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
