const Module = () => import('./Module.vue')
const Report = () => import('./views/Report.vue')

const moduleRoute = {
  path: '/report',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'AppReport',
      path: ':userId',
      component: Report,
      props: route => ({
        userId: route.params.userId,
        altId: route.params.altId,
        comparisonPage:
          String(route.query.comparisonPage).toLowerCase() === 'true',
        campaignId: route.query.campaignId,
        pdf: String(route.query.pdf).toLowerCase() === 'true',
        organisationId: route.query.organisationId,
        otp: route.query.otp,
        anonymized: !route.query.anonymized || String(route.query.anonymized).toLowerCase() === 'true',
        footballProfiles: route.query.footballProfiles === 'true',
        isTeam: String(route.query.isTeam) === 'true'
      })
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
